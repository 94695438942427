import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Typography, Button, Link, Grid, Paper } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function App() {
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      background: {
        default: '#101010',
      },
      primary: {
        main: '#00FFFF'
      },
      secondary: {
        main: '#FFFF00'
      },
    },
  });

  return (
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <Container maxWidth="sm">
          <h1>Hello</h1>
          
          <Grid container justify="center" alignItems="center" spacing="2">
          <Grid item>Rob Villeneuve</Grid>
              <Grid item><Link color="primary" href="https://twitter.com/viller613/"  target="_blank"><TwitterIcon /></Link></Grid>
              <Grid item><Link href="https://instagram.com/viller613/"  target="_blank"><InstagramIcon></InstagramIcon></Link></Grid>
              <Grid item><Link href="https://www.linkedin.com/in/robvilleneuve/"  target="_blank"><LinkedInIcon /></Link></Grid>
            </Grid>
          </Container>
      </ThemeProvider>     
  );
}

export default App;
